.commanCardStyle {
	background-color: white;
	box-shadow: 0px 3px 8px #ddd;
	padding: 12px;
	padding-bottom: 0;
	border-radius: 10px;
}
.quistionSkeleton {
	border-radius: 12px;
	grid-area: 2 / 3 / 4 / 4;
}
.MostUsedScenariosSkeleton {
	border-radius: 12px;
	grid-area: 2 / 4 / 4 / 5;
}
.Container {
	height: calc(100vh - 110px);
	overflow-y: auto;
	scrollbar-width: thin;
	&::-webkit-scrollbar {
		width: 1px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgb(216, 216, 216);
	}
	.Wrapper {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(5, 1fr);
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		.StatementRecognationSkeleton {
			grid-area: 2 / 1 / 4 / 3;
			border-radius: 12px;
		}
		.statementRecognition {
			grid-area: 2 / 1 / 4 / 3;
			@extend .commanCardStyle;
		}

		.mostScenariosUsed {
			grid-area: 2 / 4 / 4 / 5;
			@extend .commanCardStyle;
			& > svg {
				width: 100px ;
				height: 85% ;
				margin: auto;
				display: block;
			}
			ul {
				list-style: none;
				margin-top: 10px;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				justify-content: flex-start;
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					border-bottom: 1px solid #eee;
					padding: 8px;
					span {
						font-weight: 600;
						font-size: 16px;
					}
					span:last-child {
						color: #6418c3;
					}
				}
				li:last-child {
					border-bottom: none;
				}
			}
		}

		.mostFrequentQuestions {
			grid-area: 2 / 3 / 4 / 4;
			@extend .commanCardStyle;
				& > svg {
					width: 100px ;
					height: 85% ;
					margin: auto;
					display: block;
				}
				.header {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					gap: 3px;
					h3 {
						font-size: 1rem;
					}
					.tabsContainer {
						& > div {
							min-height: unset !important;
							height: 23px;
						}
						.tabLabel {
							text-transform: none;
							font-size: 15px;
							padding: 0 !important;
							min-width: unset !important;
							width: 2.2rem;
							min-height: unset;
						}
						.tabIndicator {
							background-color: #000000;
							height: 3px;
							border-radius: 10px;
						}
					}
				}
				.body {
					position: relative;
					height: 88%;
					svg {
						width: 100px;
						height: 85px;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50% , -50%);
					}
					& > div > div {
						padding: 5px 0;
					}
				}
			ul {
				list-style: none;
				margin-top: 10px;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				justify-content: flex-start;
				li:first-child a{
					border-radius: 8px 8px 0 0 !important;
				}
				li {
					width: 100%;
					border-bottom: 1px solid #eee;
					a {
						padding: 8px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						color: black;
						transition: 0.3s;
						&:hover {
							background-color: #eee;
						}
						span {
							font-weight: 600;
							font-size: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							max-width: 300px;
						}
						span:last-child {
							color: #6418c3;
						}
					}
				}
				li:last-child:not(:nth-child(1)) a{
					border-radius: 0 0 8px 8px;
				}
				li:last-child{
					border-bottom: none;
				}
			}
		}
		.CompletionScenariosSkeleton {
			grid-area: 4 / 1 / 6 / 5;
			border-radius: 12px
		}
		.completionScenarios {
			@extend .commanCardStyle;
			grid-area: 4 / 1 / 6 / 5;
			display: flex;
		}
	}
}
// media queries

@media screen and (max-width: 1600px) {
	.Container {
		height: calc(100vh - 90px) !important;
		.mostScenariosUsed {
			ul {
				li {
					span {
						font-size: 14px !important;
					}
				}
			}
		}
		.mostFrequentQuestions {
			.tabsContainer {
				.tabLabel {
					font-size: 14px !important;
				}
			}
			.body {
				& > div > div {
					padding: 0 !important;
				}
			}
			ul {
				li {
					span {
						font-size: 14px !important;
						max-width: 200px !important;
					}
				}
			}
		}
	}
}
