.container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
}

.planContentContainer {
  display: flex;
  flex-direction: column;
  padding: 0.65rem 70px 0.65rem 20px;
  
}

.planContainer {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.planWrapper {
  display: flex;
  flex-direction: row;
  border: 1px solid #6418C3;
  align-items: center;
  
}

.button {
  background-color: #6418c3;
  color: white;
  height: 3rem;
  padding: 0.5rem 1.2rem 0.5rem 1.2rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
}

.plan{
  color: #6418C3;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 0.2rem;
}

.expiry{
  color: red;
  font-size: 0.8rem;
  text-align: left;
  font-weight: 300;
}

.contetntContainer{
  display: flex;
  flex-direction: row;
  gap: 30px;
  height: auto;
}

.cardsContainer{
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 25px;
  flex: 2;
}

.botsContainer{
  flex: 1;
}

.channelsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2.5rem;
}

.singleChannelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.channelIcon {
  width: 2rem;
  height: 2rem;
}

.facebookIcon {
  width: 2rem;
  height: 2rem;
}

.iconNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}