/** ================================ Global fonts =============================*/
/* dashboard font poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap');

/** ================================ Global Overriding =============================*/
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	/* background-color: #eeeeee !important; */

	/* font-size: 16px !important;
	 */
	/* font-size: '18px'; */
	/* line-height: '27px'; */
	margin: 0;
	font-family: 'Poppins', sans-serif !important;
	scrollbar-width: thin;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 5px;
}

/* scrollbar in firefox */

body {
	scrollbar-width: thin;
	scrollbar-color: rgb(119, 119, 119);
}

/* scrollbar in google Chrome */

body::-webkit-scrollbar {
	width: 4px;
	height: 3px;
}

body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
	background-color: rgb(119, 119, 119);
	border-radius: 5px;
}

/* Code for Firefox */
/* ::-moz-selection { 
  color: red;
  background: yellow;
}

::selection {
  color: red;
  background: yellow;
} */

/** input,button **/
input,
button,
textarea,
a {
	background: none;
	border: none;
	font-family: 'Poppins';
}

a {
	cursor: pointer;
	text-decoration: none;
}

select {
	border: none;
	font-family: 'Poppins';
}

input:focus,
button:focus,
select:focus,
textarea {
	outline: none;
}

textarea {
	resize: none;
}

iframe {
	display: none;
}

/** svg **/
svg {
	cursor: pointer;
	/* width: 25px; */
}

.LOADING_SCREEN_GLOBAL {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	background: #f4f7f9;
	align-items: center;
}

/** ================================ Canvas Styling =============================*/

/* column is invisable just to detect the changing in the workspace */

/* media q for font size */
/* 
@media screen and (min-width: 1600px) {
	body,
	html {
		font-size: 16px !important;
	}
}
@media screen and (min-width: 1500) and (max-width: 1599px) {
	body,
	html {
		font-size: 15px !important;
	}
}

@media screen and (min-width: 1440px) and (max-width: 1499px) {
	body,
	html {
		font-size: 14px !important;
	}
}

@media screen and (min-width: 1400px) and (max-width: 1439px) {
	body,
	html {
		font-size: 13px !important;
	}
}
@media screen and (min-width: 1368px) and (max-width: 1399px) {
	body,
	html {
		font-size: 12px !important;
	}
}

@media screen and (min-width: 1300px) and (max-width: 1367px) {
	body,
	html {
		font-size: 11px !important;
	}
}

@media screen and (min-width: 1280px) and (max-width: 1299px) {
	body,
	html {
		font-size: 10px !important;
	}
}

@media screen and (min-width: 1240px) and (max-width: 1279px) {
	body,
	html {
		font-size: 9px !important;
	}
}

@media screen and (min-width: 1200px) and (max-width: 1239px) {
	body,
	html {
		font-size: 9px !important;
	}
}

@media screen and (min-width: 1152px) and (max-width: 1199px) {
	body,
	html {
		font-size: 9px !important;
	}
}
@media screen and (min-width: 1024px) and (max-width: 1151px) {
	body,
	html {
		font-size: 9px !important;
	}
}
@media screen and (min-width: 960px) and (max-width: 1023px) {
	body,
	html {
		font-size: 9px !important;
	}
}
@media screen and (min-width: 400px) and (max-width: 959px) {
	body,
	html {
		font-size: 8px !important;
	}
}

@media screen and (max-width: 399px) {
	body,
	html {
		font-size: 6px !important;
	}
} */

@media screen and (min-width: 1600px) {
	body,
	html {
		font-size: 16px !important;
	}
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
	body,
	html {
		font-size: 12px !important;
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	body,
	html {
		font-size: 11px !important;
	}
}

@media screen and (min-width: 600px) and (max-width: 991px) {
	body,
	html {
		font-size: 10px !important;
	}
}

@media screen and (max-width: 599px) {
	body,
	html {
		font-size: 8px !important;
	}
}
