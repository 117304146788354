.container {
	flex: 1;
	.header {
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		.viewMoreBtn {
			margin-left: auto;
			color: #6418c3;
			text-decoration-line: underline;
			padding-bottom: 0;
			font-size: 13px;
			font-weight: 600;
			cursor: pointer;
		}
	}

	.wrapper {
		display: flex;
		height: 200px;

		.details {
			display: flex;
			flex-direction: column;
			justify-content: center;
			row-gap: 20px;
			border-left: 1px solid #ddd;
			padding-inline: 12px;

			.item > p {
				&:last-child {
					font-weight: bold;
				}
			}
		}
	}
}
