.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
    height: 35px;
    width: 100%;
    margin-bottom: 10px;
    .leftPart {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 15px;
      .backButton {
        color: #6418C3;
        font-size: 17px;
        background-color: rgba($color: #6418C3, $alpha: 0.1);
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
  
        &:hover {
          background-color: rgba($color: #6418C3, $alpha: 0.2);
        }
      }
      .headtext {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
  }

  .body {
    width: 100%;
    height: calc(100vh - 80px);
    
    .completionScenarios {
      background-color: white;
      box-shadow: 0px 3px 8px #ddd;
      padding: 12px;
      border-radius: 10px;
      margin-bottom: 10px;      
    }
    .CompletionScenariosSkeleton {
      padding: 12px;
      margin-bottom: 10px;      
      border-radius: 10px;
    }

    .projectTable {
      width: 100%;
      background-color: white;
      box-shadow: 0px 3px 8px #ddd;
      padding: 12px;
      padding-right: 0;
      border-radius: 10px;

      .noData {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-block: 25px;
        & >  svg {
          width: 100px;
          height: 100px;
        }
      }
      thead th{
          text-align: center;
          height: 30px !important;
          font-weight: 600;
          font-size: 16px;
      }
      tbody {
        display: block;
        max-height: calc(100vh - 410px);
        overflow-y: auto;
        scrollbar-width: thin;
        td {
          text-align: center;
        }
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgb(198, 198, 198);
        }
      }
      thead,tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
      tr {
        padding-block: 10px;
        border-bottom: 1px solid #eee;
      }
      tr:last-child {
        border-bottom: 0;
      }
    }
  }
}


@media screen and (max-width:1600px) {
  .projectTable {
    tbody {
      max-height: calc(100vh - 400px) !important;
    }
    th {
      font-size: 14px !important;
    }
    td {
      font-size: 14px;
      padding-top: 8px;
    }
  }
}