.container {
	height: 50px;
	border-bottom: 1px solid #ddd;
	.wrapper {
		max-width: 1440px;
		width: 100%;
		margin: auto;
		padding: 20px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logoContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			column-gap: 15px;
			img {
				height: 25px;
			}
			h2 {
				font-weight: 500;
				font-size: 17px;
			}
		}
	}
}
