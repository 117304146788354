.Container {
  height: 100vh;
  width: 100%;
  padding-block: 15px;
  @media screen and (max-width: 1600px) {
    padding-block: 10px;
  }
}


