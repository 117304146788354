
.sentimentBar {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.colorBox {
  width: 13px;
  height: 13px;
  border-radius: 5px;
}

.genderInfo {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 15px;
}

.title {
  font-weight: bold;
}

.result {
  font-weight: 700;
}