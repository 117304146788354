.dropdown {
  position: absolute;
  background-color: #fff;
  width: 100%;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 8px 8px;
  z-index: 9999;
  box-shadow: 0px 3px 8px #ddd;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 10px;
      padding: 10px 20px;
      transition: 0.3s;
      width: 100%;
      svg {
        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;
      }
    }
    li:hover {
      background-color: #eee;
    }
  }
}

