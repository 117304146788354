.mainContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 0.5rem;
  border: 1px solid #E4E4E2;
  padding: 20px;
  max-height: 84vh;
  
}

.title {
  font-size: 1.1rem;
}

.botsContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-right: 3rem;
  overflow: auto;
}

.singleBotContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid #E4E4E2;
  padding: 2rem 3rem 2rem 3rem ;
  gap: 30px;
  border-radius: 0.5rem;
  justify-content: space-between;
  width: 100% !important;
}

.imageNameContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 6rem;
}

.botImage {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.botName {
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
}

.divider {
  background-color: #E4E4E2;
  width: 1px;
}

.botInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.customFields {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
}

.botInfoText {
  font-size: 1rem;
  margin-right: 2rem;
}

.botInfoValue {
  color: #6418c3;
  font-size: 1.1rem;
  font-weight: 800;
}


.tooltipContainer {
  position: relative;
  display: inline-block;
  
}

.texts {
  cursor: pointer;
  text-decoration: underline;
}

.tooltip {
  position: absolute;
  background-color: white;
  border-radius: 0.5rem;
  padding: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  top: -4rem; 
  left: -6rem;
  transform: translateX(-50%);
  pointer-events: auto;
  font-weight: 300;
  font-size: 15px;
  
}





.channelsContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0.5rem;
}

.singleChannelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  color: black;
}

.channelIcon {
  width: 1rem;
  height: 1rem;
}

.facebookIcon {
  width: 2rem;
  height: 2rem;
}

.iconNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}