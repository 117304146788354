.genderSkeleton {
	border-radius: 12px;
	width: 100%;
}
.Container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	row-gap: 15px;
	height: calc(100vh - 110px);
	overflow-y: auto;
	scrollbar-width: thin;
	&::-webkit-scrollbar {
		width: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgb(216, 216, 216);
	}
	.upperContainer {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(5, minmax(185px, 1fr));
		gap: 15px;
	}
	.lowerContainer {
		width: 100%;
		display: flex;
		align-items: flex-start;
		column-gap: 15px;
		.ChartsContainer {
			flex: 9;
			display: grid;
			grid-template-columns: repeat(2, minmax(250px, 1fr));
			gap: 15px;

			.wrapper {
				background-color: white;
				box-shadow: 0px 3px 8px #ddd;
				padding: 12px;
				border-radius: 10px;
				width: 100%;
				min-height: 280px;

				.message {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
				}
			}
		}
		.ProgressContainer {
			flex: 3;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			row-gap: 15px;
			.ConversationRatingCard {
				background-color: white;
				box-shadow: 0px 3px 8px #ddd;
				padding: 12px;
				border-radius: 10px;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				row-gap: 23px;
				min-height: 340px;
				.header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
				}
			}
			.sentimentGenderContainer{
				display: flex;
				flex-direction: column;
				gap: 13px;
				width: 100%;
			}
			.sentimentContainer,
			.AssignedCustomersContainer {
				background-color: white;
				box-shadow: 0px 3px 8px #ddd;
				padding: 15px;
				border-radius: 10px;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				row-gap: 10px !important;
				grid-gap: 10px !important;
				.itemsContainer {
					display: flex;
					align-items: flex-start;
					justify-content: center;
					gap: 15px;
					width: 100%;
					flex-direction: column;
					.item {
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;
						column-gap: 10px;
						
						.colorBox {
							width: 13px;
							height: 13px;
							border-radius: 5px;
							margin-top: 3px;
						}
						.genderInfo {
							display: flex;
							flex-direction: row;
							gap: 8px;
							font-size: 15px;
							justify-content: space-between;
							
							.title {
								width: 70px; 
								font-size: 15px;
							}
							.result {
								font-weight: 700;
								display: flex;
								justify-content: end;
								width: 42px;
							}
						}
						.itemInfo {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							justify-content: flex-start;
							font-size: 15px;
							.result {
								font-weight: 700;
							}
						}
					}
				}
			}
			.AssignedCustomersContainer {
				row-gap: 15px;
				max-height: 184px;
			}
		}
	}
}
.genderInfoContainer {
	display: flex;
					align-items: center;
					justify-content: center;
					gap: 25px;
					width: 100%;
					flex-direction: column;
					.item {
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;
						column-gap: 10px;
						
						.colorBox {
							width: 13px;
							height: 13px;
							border-radius: 5px;
							margin-top: 3px;
						}
						.genderInfo {
							display: flex;
							flex-direction: row;
							gap: 8px;
							font-size: 15px;
							justify-content: space-between;
							
							.title {
								width: 70px; 
								font-size: 15px;
							}
							.result {
								font-weight: 700;
								display: flex;
								justify-content: end;
								width: 42px;
							}
						}
						.itemInfo {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							justify-content: flex-start;
							font-size: 15px;
							.result {
								font-weight: 700;
							}
						}
					}
				}



.assignedGenderContainer {
	display: flex;
	flex-direction: row;
	gap: 10px;
}
.infoContainer {
	display: flex;
	flex-direction: row;
}

.sentimentContainer {
	min-height: 140px !important;
	gap: 10px !important;
}

.sentimentInfoContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	gap: 10px;
}

.sentimentInfoWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.result {
		font-weight: 700;
		display: flex;
		justify-content: end;
		width: 42px;
	}
}

.sentimentIconText {
	display: flex;
	flex-direction: row;
	gap: 5px;
}

// media queries

@media screen and (max-width: 1600px) {
	.Container {
		row-gap: 10px;
		height: calc(100vh - 80px);
	}
	.upperContainer {
		gap: 8px !important;
	}
	.genderInfoContainer {
		gap: 20px;
	}
	.itemsContainer {
		gap: 15px !important;
		grid-gap: 15px !important;
	}
	.lowerContainer {
		column-gap: 10px !important;
		.ChartsContainer {
			gap: 10px !important;
		}
		.ProgressContainer {
			gap: 9px !important;
		}
	}
	.ConversationRatingCard {
		row-gap: 22px !important;
	}
	.sentimentContainer {
		row-gap: 23px !important;
	}
	.AssignedCustomersContainer {
		row-gap: 13px !important;
		min-height: 180px !important;
	}
	.sentimentGenderContainer {
		gap: 18px !important;
	}
}


@media screen and (max-width: 1274px) {
	.upperContainer {
		display: grid;
		grid-template-columns: repeat(3, minmax(185px, 1fr)) !important;
	}
	.ChartsContainer {
		grid-template-columns: repeat(2, minmax(250px, 1fr)) !important;
		width: 100%;
	}
.lowerContainer {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.ProgressContainer {
	display: flex;
	flex-direction: row !important;
	width: 100%;
	.ConversationRatingCard {
		flex: 5;
		row-gap: 35px !important;
	}
	.sentimentGenderContainer{
		flex: 5;
		display: flex;
		flex-direction: column;
		gap: 18px !important;
	}
}
.assignedGenderContainer {
	width: 100%;
}
}
