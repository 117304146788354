.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  .dropDawnContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    cursor: pointer;
    .filters {
      background-color: white;
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      box-shadow: 0px 3px 8px #ddd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      width: 220px;
      svg:first-child{
        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;
      }
    }
  }
}

// media queries


@media screen and (max-width: 1600px) {
  .filters {
    font-size: 14px !important;
  }
}