.commanCardStyle {
	background-color: white;
	box-shadow: 0px 3px 8px #ddd;
	padding: 12px;
	border-radius: 10px;
}

.Container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(9, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	& > div:nth-child(1),
	& > span:nth-child(1) {
		grid-area: 1 / 1 / 2 / 3;
	}
	& > div:nth-child(2),
	& > span:nth-child(2) {
		grid-area: 1 / 3 / 2 / 5;
	}
	& > div:nth-child(3),
	& > span:nth-child(3) {
		grid-area: 1 / 5 / 2 / 7;
	}
	.BotActivitesSkeleton {
		grid-area: 2 / 1 / 6 / 7;
		border-radius: 12px;
	}
	.ActivitesContainer {
		grid-area: 2 / 1 / 6 / 7;
		@extend .commanCardStyle;
		.noDataContainer {
			position: relative;
			height: calc(100vh - 315px);
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50% , -50% );
				width:100px;
				height:80px;
			}
		}
		.listactivities {
			position: relative;
			height: calc(100vh - 315px);
			overflow-y: auto;
			list-style: none;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			row-gap: 10px;
			margin-top: 15px;
			padding: 1px 5px 0 1px;
			width: 100%;
			scrollbar-width: thin;
			&::-webkit-scrollbar {
				width: 5px;
			}
			// &::-webkit-scrollbar-thumb {
			// 	background-color: rgb(216, 216, 216);
			// }
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				column-gap: 10px;
				width: 100%;
				.basicInfo {
					display: flex;
					align-items: center;
					column-gap: 10px;
					img {
						min-width: 40px;
						max-width: 40px;
						min-height: 40px;
						max-height: 40px;
						border-radius: 50%;
						border: 1px solid transparent;
						outline: 1px solid #c393ff;
					}
					.name {
						font-weight: 600;
					}
				}
				.numberOfMsg {
					color: #6418c3;
					font-weight: 600;
				}
			}
		}
	}
	.TeamMemberMessagesSkeleton {
		grid-area: 1 / 7 / 6 / 10;
		border-radius: 12px;
	}

	.TeamMemberMessageContainer {
		grid-area: 1 / 7 / 6 / 10;
		@extend .commanCardStyle;

		.noDataContainer {
			position: relative;
			height: calc(100vh - 185px);
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50% , -50% );
				width:100px;
				height:80px;
			}
		}
		.wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			justify-content: space-between;

			.totalMsgs {
				font-weight: bold;
			}
		}

		ul {
			position: relative;
			height: calc(100vh - 185px);
			overflow-y: auto;
			list-style: none;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			row-gap: 10px;
			margin-top: 15px;
			padding: 1px 5px 0 1px;
			scrollbar-width: thin;
			&::-webkit-scrollbar {
				width: 1px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgb(216, 216, 216);
			}
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				column-gap: 10px;
				width: 100%;
				.basicInfo {
					display: flex;
					align-items: center;
					column-gap: 10px;
					img {
						min-width: 40px;
						max-width: 40px;
						min-height: 40px;
						max-height: 40px;
						border-radius: 50%;
						border: 1px solid transparent;
						outline: 1px solid #c393ff;
					}
					.name {
						font-weight: 600;
					}
				}
				.numberOfMsg {
					color: #6418c3;
					font-weight: 600;
				}
			}
		}
	}
}

// media queries

@media screen and (max-width: 1600px) {
	.Container {
		.TeamMemberMessageContainer , .noDataContainer {
			ul {
				height: calc(100vh - 145px) !important;
				li {
					.basicInfo {
						img {
							min-width: 33px !important;
							max-width: 33px !important;
							min-height: 33px !important;
							max-height: 33px !important;
						}
					}
				}
			}
		}
		.listactivities , .noDataContainer {
			height: calc(100vh - 260px) !important;
		}
	}
}
