$sidebar-bg-color: #ffffff;

$highlight-color: #6418c3 !default;

$sidebar-color: #626262 !default;

$menu-color: red !default;

$icon-bg-color: #dfecff !default;

$icon-color: '#626262';

$submenu-bg-color: #ffffff !default;
$submenu-bg-color-collapsed: #ffffff !default;

$sidebar-width: 100% !default;

$sidebar-collapsed-width: 100% !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';
