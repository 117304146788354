.mediaContainer {
  height: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.headerContainer {
  height: 3.8rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  
}