.skeleton {
	border-radius: 12px;
	width: 100%;
}
.container {
	background-color: white;
	box-shadow: 0px 3px 8px #ddd;
	padding: 12px;
	border-radius: 10px;
	width: 100%;
	min-height: 340px;
	& > svg {
		width: 100px ;
		height: 100% ;
		margin: auto;
		display: block;
	}
	.wrapperV {
		margin-top: 2rem;
		width: 100% !important;
		max-width: 100%;
		& >  svg {
			max-width: 100%;
		}
	}
	.wrapper {
		height: 230px;
		overflow-y: auto;
		overflow-x: hidden;
		width: 100% !important;
		max-width: 100%;
		scrollbar-width: thin;
		// background-color: #eee;
		&::-webkit-scrollbar {
			width: 1px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: rgb(216, 216, 216);
		}
	}
		.verticalListContainer {
			list-style: none;
			.item {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				column-gap: 10px;
				margin-bottom: 5px;
				.colorBox {
					width: 13px;
					height: 13px;
					border-radius: 5px;
					margin-top: 3px;
				}
				.itemInfo {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					row-gap: 5px;
					.title {
						font-weight: 600;
					}
					.result {
						font-weight: 700;
					}
				}
			}
		}

		.horizontalListContainer {
			list-style: none;
			height: 50px;
			display: grid;
			grid-template-columns: repeat(4 , 1fr);
			.item {
				display: flex;
				column-gap: 5px;
				.colorBox {
					width: 13px;
					height: 13px;
					border-radius: 5px;
					margin-top: 3px;
				}
				.itemInfo {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					row-gap: 5px;
					.title {
						font-weight: 600;
					}
					.result {
						font-weight: 700;
					}
				}
			}
		}
	}


// media queries

@media screen and (max-width: 1600px) {
	.container {
		// min-height: 243px !important;
		.wrapper {
			height: 200px !important;
		}
		.horizontalListContainer {
			grid-template-columns: repeat(3 , 1fr) !important;
			height: 35px !important;
		}
	}
	.wrapperV {
		margin-top: 4rem !important;
	}
}
