.Container {
  height: calc(100vh - 15px);
  width: 100%;
  padding-block: 10px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    height: 50px;
    margin-bottom: 5px;
    .tabsContainer {
      background: white;
      border-radius: 25px;
      overflow: hidden;
      padding-inline: 25px;
      box-shadow: 0px 3px 8px #ddd;
      .tabLabel {
        text-transform: none;
        font-size: 15px;
      }
      .tabIndicator {
        background-color: #6418C3;
        height: 3px;
        border-radius: 10px;
      }
    }
  }
  .body {
    & > div > div {
      padding: 15px 0;
    }
  }
}


// media queries

@media screen and (max-width: 1600px) {
  .Container {
    padding-block: 0px !important;
  }
  .tabsContainer {
    .tabLabel {
      font-size: 14px !important;
    }
  }
  .body {
    & > div > div {
      padding: 5px 0 !important;
    }
  }
}