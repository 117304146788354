.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 5px;
	width: 100%;
	& > span:first-child ,
	& > span:last-child {
		width: 60px;
	}
	& > span:last-child {
		color: #6418C3;
		font-weight: 600;
		text-align: right;
	}
}


// media queries

@media screen and (max-width: 1600px) {
	.container {
		column-gap: 0px;
		& > span:first-child ,
		& > span:last-child {
			width: 50px !important;
		}
	}
}