.pageContainer {
  min-height: calc(100vh - 60px);
  .pageWrapper {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 20px;
    position: relative;
  }
  .fluidPageWrapper {
    max-width: 100%;
    padding: 0;
  }
}