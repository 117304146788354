.profileImg {
	width: 45px;
	height: 45px;
	border-radius: 100px;
	border: 2px solid #0000ff;
	box-shadow: 0px 3px 30px #0000000f;
}
.dropdown-toggleCustomize::before {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: '';
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
	margin-right: 10px;
}
.hiCaptionStyle {
	font-weight: 600;
}
.onlineVariant {
	position: absolute;
	top: -5px;
	right: -6px;
	background: #0000fb 0% 0% no-repeat padding-box;
	width: 8px;
	height: 8px;
	border-radius: 100%;
}
