.skeleton {
  border-radius: 10px;
}
.Container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 8px #ddd;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 8px;
  // height: fit-content;
  svg {
    min-width: 41px;
    max-width: 41px;
    max-height: 41px;
    max-height: 41px;
  }
  .cardTitle {
    color: #808080;
    font-size: 1rem;
    font-weight: 400;
  }
  .result {
    color: #101010;
    font-size: 18px;
    font-weight: 700;
  }
}

// media queries

@media screen and (max-width: 1600px) {
  .Container {
    svg {
      min-width: 30px;
      max-width: 30px;
      max-height: 30px;
      max-height: 30px;
    }
    .cardTitle {
      color: #808080;
      // font-size: 13px;
      font-weight: 400;
    }
    .result {
      color: #101010;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 1274px) {
  .Container {
    svg {
      min-width: 25px;
      max-width: 25px;
      max-height: 25px;
      max-height: 25px;
    }
    .cardTitle {
      color: #808080;
      // font-size: 13px;
      font-weight: 300;
    }
    .result {
      color: #101010;
      font-size: 12px;
      font-weight: 700;
    }
  }
}
