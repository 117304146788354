.container {
	position: relative;
	height: 100%;
		& > svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50% , -50%);
			width: 100px;
			height: 100px;
	}
	.wrapper {
		.verticalListContainer {
			list-style: none;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			row-gap: 20px;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				top: 47%;
				left: 0;
				background-color: #ccc;
				transform: translateY(-47%);
			}
			.item {
				display: flex;
				// flex-basis: 50%;
				align-items: flex-start;
				justify-content: flex-start;
				column-gap: 7px;
				margin-bottom: 5px;
				.colorBox {
					min-width: 13px;
					max-width: 13px;
					min-height: 13px;
					max-height: 13px;
					border-radius: 5px;
					margin-top: 3px;
				}
				.itemInfo {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					row-gap: 5px;
					.title {
						font-weight: 600;
					}
					.result {
						font-weight: 700;
					}
				}

				.itemWrapper {
					display: flex;
					align-items: center;
					gap: 4px;

					.colorBox {
						min-width: 6px;
						max-width: 6px;
						min-height: 6px;
						max-height: 6px;
						border-radius: 50%;
					}

					.language {
						margin-right: 15px;
					}

					.counter {
						font-weight: bold;
					}
				}
			}
			.item:nth-child(even) {
				flex-basis: 58%;
			}
			.item:nth-child(odd) {
				flex-basis: 40%;
			}
			.item:nth-child(3) {
				border-right: 1px solid #ccc;
			}
			.item:last-child {
				flex-direction: column;
			}
		}
	}
}

// media queries

// @media screen and (max-width: 1600px) {
// 	.container {
// 		height: 38vh !important;
// 	}
// }
